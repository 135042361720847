<template>
  <div class="col-12 col-lg-6 c">
    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <label for="">الإدارة العامة للتعليم بمنطقة</label>
          <input
            type="text"
            class="form-control"
            v-model="info.edara"
            @change="changeme()"
          />
        </div>
        <div class="form-group">
          <label for="">مكتب التعليم</label>
          <input
            type="text"
            class="form-control"
            v-model="info.maktab"
            @change="changeme()"
          />
        </div>
        <div class="form-group">
          <label for="">اسم المدرسة</label>
          <input
            type="text"
            class="form-control"
            v-model="info.school"
            @change="changeme()"
          />
        </div>
        <div class="form-group">
          <label for="">مدير المدرسة</label>
          <input
            type="text"
            class="form-control"
            v-model="info.admin"
            @change="changeme()"
          />
        </div>
        <div class="form-group">
          <label for="">رئيس لجنة الإشراف</label>
          <input
            type="text"
            class="form-control"
            v-model="info.boss"
            @change="changeme()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: localStorage.getItem("info")
        ? JSON.parse(localStorage.getItem("info"))
        : {
            edara: "",
            school: "",
            admin: "",
            boss: "",
            maktab: "",
          },
    };
  },
  methods: {
    changeme() {
      localStorage.setItem("info", JSON.stringify(this.info));
    },
  },
};
</script>

<style>
</style>